import React, { useContext } from 'react';
import "../design/Intro.css"
import  { Jumbotron, Container } from "react-bootstrap";
import cartoon_img from "../images/main_bg.png";
import cargo from "../images/cargo.png"
import { AuthContext } from "../context/auth-context";

function Intro(props) {

    // login AuthContext
    const loginContext = useContext(AuthContext);

    const visitorLogin = () => {
      return (
        "Signed in as: visitor"    
      );
    }

    return (
      <React.Fragment>
        <Jumbotron>
        <div className="login_context">
        {!!loginContext.token ? `Signed in as: ${loginContext.email}` : visitorLogin()}
        </div>
          <Container>
            <div className="sun-div">
              <img id="sun-img" alt="" src={props.sun} />
            </div>
            <div id="container-sm">
              <div id="app-0">
                <span className="intro-0">I'm Ruslan. </span>
              </div>
              <div id="app-1">
                <span className="intro-1">a self-taught developer!</span>
              </div>
            </div>
            <div className="airplane-div">
                <img id="airplane-img" alt="" src={props.airplane} />
            </div>
            <div className="cargo-div">
              <img id="cargo-img" alt="" src={cargo} />
            </div>
          </Container>
          <div className="cartoon-div">
              <img alt="" className='cartoon' src={cartoon_img } />
            </div>
        </Jumbotron>
      </React.Fragment>
    );
  }
  
  export default Intro;