import { React, useState } from 'react';
import {  Modal, Button } from 'react-bootstrap'

function ErrorModal(props) {
  const [show, setShow] = useState(false);

  // hook state to parent to reset errorModal hook
  const handleShow = () => {
    setShow(false);
    props.errorHandler(show);
  };

    return (
      <>
        <Modal
          show={props.show}
          backdrop="static"
          keyboard={false}
          dialogClassName="modal-90w"
          aria-labelledby="example-custom-modal-styling-title"
        >
          <Modal.Header>
            <Modal.Title id="example-custom-modal-styling-title">
              Error!
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>{props.errorMsg}</p>
          </Modal.Body>
          <Modal.Footer><Button variant = "primary" onClick = {handleShow}>Close</Button></Modal.Footer>
        </Modal>
      </>
    );
  }
export default ErrorModal;