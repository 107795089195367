import React, { useState, useEffect, useCallback } from 'react';
import { Modal, Form, Button } from 'react-bootstrap';
import "../design/EditModal.css"

const EditModal = (props) => {

    // show modal hook
    const [modalShow, setModalShow] = useState(false);

    const closeEditModal = () => {
        setModalShow(false);
        props.hideModal(modalShow)
    }

    // edit wip hook
    const [ wipEdit, setWipEdit ] = useState({
        title: "Title",
        description: "Entry"
    });

    // event handler for email and password hook object
    const handleFormChange = (event) => { 
        const { name, value } = event.target;
        setWipEdit((prev) => {
            return {
            ...prev,
            [name]: value
            }
        })
    };

    // edit form to show record selected!
    useEffect(()=>{
        if(props.show){
            if(!!props.prepop){
                setWipEdit({
                    title: props.prepop.title,
                    description: props.prepop.description
                })
            }
        }
    },[props.show, props.prepop])

    const editItem = useCallback(() => {
        props.editItem(wipEdit)
    },[wipEdit, props]);

    return (
        <React.Fragment>
            <>
            <Modal
                show={props.show}
                onHide={() => setModalShow(false)}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                Edit Wip Entry
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit = {(e)=>e.preventDefault()}>
                    <Form.Group className="mb-3" controlId="title">
                        <Form.Label>Title</Form.Label>
                        <Form.Control type="title" name = "title" value = {wipEdit.title} onChange = {handleFormChange} placeholder="Title" />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="description">
                        <Form.Label>Wip Entry</Form.Label>
                        <Form.Control as="textarea" name = "description" value = {wipEdit.description} onChange = {handleFormChange} placeholder = "Note" rows={3} />
                    </Form.Group>
                </Form>
                <Modal.Footer>
                    <Form.Text className= {!!props.error ? "edit-text" : "hidden"}>{`${props.error}`}</Form.Text>
                    <Button id = "close-button" variant="danger" onClick = {closeEditModal}>Close</Button>
                    <Button id = "submit-button" variant="success" type="submit" onClick = {editItem}>Submit</Button>
                </Modal.Footer>
            </Modal.Body>
        </Modal>
            </>
        </React.Fragment>
    );
}

export default EditModal;