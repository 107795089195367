import React, { useState, useEffect, useContext } from 'react';
import "../design/Wip.css";
import wip_btn from "../images/wip-btn.png";
import WipEntry from './WipEntry';
import ErrorModal from './ErrorModal';
import Login from "../components/Login";
import { AuthContext } from "../context/auth-context"; 
import NewWipEntry from './NewWipEntry';
import { css } from "@emotion/react";
import PacmanLoader from "react-spinners/PacmanLoader";



function Wip(props) {

  // update wip API data
  const [ titleDesc, setTitleDesc ] = useState("");

  // status spinner
  const [isLoading, setLoading] = useState(false);
  
  // error hooks for modal
  const [ error, setError ] = useState(false);
  const [ errorMsg, setErrorMessage ] = useState("");

  // Login modal show trigger
  const [ loginPrompt, setLoginPrompt ] = useState(false);

  // Wip entry form
  const [ newWipEntry, setNewWipEntry ] = useState(false);

  // login AuthContext
  const loginContext = useContext(AuthContext);

  // delete wipEntry
  const url = process.env.REACT_APP_BACKEND_URL;
  const deleteWipEntryApi = async(id) => {
    try {
      setLoading(true);
      const deleteWip = await fetch(`${url}/wip/delete/${id}`, {
        method: 'DELETE',
        headers: {
          Authorization:  `Bearer ` + loginContext.token
        }
      });
      if(!deleteWip.ok){ 
        setError(!deleteWip.ok)
        setLoading(false)
        // TO-DO: either throw error or route to log in!
        throw new Error(`Something went wrong, please try again!`);
      } else {
        setLoading(false)
        // update the data currently displayed, but before the render
        setTitleDesc(prevItem => prevItem.filter(item => item.id !== id));
        alert('Delete Success!')
      }
    } catch (err) {
      setLoading(false)
      // if error, populate hook for error modal
      setErrorMessage(err.message)
      // TO-DO: error first then log in!
    }
  }

  useEffect(()=>{
    if(props.fetchWip){
      fetchRecords();
    }
  },[props.fetchWip])

  // populate all the records for Wip on page load
  const fetchRecords = async() => {
    setLoading(true);
    try{
      const url = process.env.REACT_APP_BACKEND_URL;
      const response = await fetch (`${url}/wip/records/all`, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        headers: {
          'Content-Type' : 'application/json'
        }
      });
      // store output 
      const responseData = await response.json();
      // status spinner update if data is available from mongodb but before hook is updated
      if(response.ok && response.status === 201) {
        setLoading(false)
        setTitleDesc(responseData.entries);
      }
      if(!response.ok){
        setError(!response.ok)
        throw new Error(`Could not pull data from mongodb. Please refresh your page!`)
      }
      // responseData.entries.map(item => console.log(item.title, item.description))
    } catch (err) {
      setLoading(false)
      // if error, populate hook for error modal
      setErrorMessage(err.message)
    }
  }

  // filter out the deleted Id on a render
  const deleteWipItem = (deletedItemId) => {
    deleteWipEntryApi(deletedItemId);
  }

  // reset the error Modal
  const errorModalHandler = (state) => {
    setError(state);
  }

  // new Wip Entry Form Handler
  const wipFormHandler = () => {
    setNewWipEntry(false);
  }

  // login modal handler: login or allow new entry
  const localStorageHandler = () => {
    // check if user is logged in
    if(!loginContext.isLoggedIn) {
      // if no loginPrompt, switch
      !loginPrompt ? setLoginPrompt(true) : setLoginPrompt(false);
    } else if(loginContext.isLoggedIn) {
      // user is logged in, new Wip entry
      setLoginPrompt(false);
      setNewWipEntry(true);
    }
  }

  const override = css`
    position: flex; /* Sit on top of the page content */
    top: 50%;
    bottom: 0;
    left: 50%;
    right: 0;
    z-index: 99999; /* Specify a stack order in case you're using a different order for other elements */
  `;

  const color = "#36D7B7"

  return (
    <React.Fragment>
      {isLoading && (<div className="pacman"><PacmanLoader color={color} css={override} loading={isLoading} size={25} /></div>)}
      <ErrorModal show = {error} errorHandler = {errorModalHandler} errorMsg = {errorMsg} />
        <div className = {"wip"}>
          <div className = "wip-0">
            Work in Progress!
          </div>
          <div className = "wip-1">
            A behind the scenes look at my work in progress, design inspirations,<br/>
            questionable ideas, & random life events/updates.
          </div>
        </div>
        {!isLoading && titleDesc && (titleDesc.map(titleDesc =>
          <WipEntry
            key = {titleDesc.id}
            id = {titleDesc.id}
            title = {titleDesc.title}
            createdAt = {titleDesc.createdAt}
            description = {titleDesc.description}
            wipUpdates = {props.wipUpdates}
            deleteAction = {deleteWipItem}
            refresh = {fetchRecords}
          />
        ))}
        <Login show = {loginPrompt} hideModal = {localStorageHandler} />
        {newWipEntry && (<NewWipEntry refresh = {fetchRecords} close = {wipFormHandler} />) }
        <div className = {"wip_btn"}>
          <img alt="wip_bird" onClick = {localStorageHandler} src = {wip_btn} />
        </div>
    </React.Fragment>
  )
}

export default Wip;