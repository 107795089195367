import { useState, useCallback, useEffect } from 'react';

let logoutTimer;

export const useAuth = () => {

    const [token, setToken ] = useState(false);

    // we're using state to control expiration timer
    // expiration timer changes whenever er call login, we get a new expiration date/time
    // we're storing this as date
    // // because of scoping we can name this variable the same as the one below
    const [ tokenExpirationDate, setTokenExpirationDate ] = useState();


    // we are adding a userId as a new state to add to creator in the NewPlaces.js file
    const [ userId, setUserId ] = useState(false);

    // 06/2021 adding email to check user for comments vs Wip entries
    const [ email, setUserEmail ] = useState(false);

    // useCallback is used in this project to avoid inifinite loops.
    // by adding a creator, we are now expecting a user uid as an argument
    const login = useCallback((uid, token, email, expirationDate) => {
        
        // call hook to set email
        setUserEmail(email);
        
        setToken(token);
        // call hook and set to uid
        setUserId(uid);
        // this date element is for our token that will expire
        // however, we want to check to see if we already have a token and use that as the expiration vs
        // assigning a new expiration
        const tokenExpirationDate = expirationDate || new Date(new Date().getTime() + (1000 * 60 * 60)); // newDate() gives current date with time in hours, hence the math
        setTokenExpirationDate(tokenExpirationDate);
        // local storage access. this is where we store data so access is 
        // maintained by the browser
        localStorage.setItem(`userData`, 
        JSON.stringify({
            email: email,
            userId: uid, 
            token: token, 
            expiration: tokenExpirationDate.toISOString() // ISO ensure no data is lost when data is stringified
        }))
    }, []);

    // useCallback is used in this project to avoid inifinite loops.
    const logout = useCallback(() => {
        setToken(null);
        // we need to set toke expiration to null when we logout
        setTokenExpirationDate(null);
        // when we logout we want to set the userId uid to null
        setUserId(null);

        //when logged out, set email to null
        setUserEmail(null);
        
        // we now feed it into our context below
        //when we log out we need to clear the local storage
        localStorage.removeItem('userData');
    }, []);
    // set value to equal the value itself so we can pass it down 
    // to all the other components that are looking for the data

    // this is used for the tuner
    useEffect(() => {
        if(token && tokenExpirationDate) {
            // point to callback and thanks to useCallback, it is never re-created and we don't have an infinite loop
            // we need to calc the remainder expiration time 
            const remainingTime = tokenExpirationDate.getTime() - new Date().getTime();
            logoutTimer = setTimeout(logout, remainingTime);
        } else {
            // if no token or token expiration time, clear all ongoing timers
            // we could not have any because we logged out
            clearTimeout(logoutTimer);
        }
    }, [token, logout, tokenExpirationDate])

    // because there are no dependencies, this 
    // func will only run once when the component mounts or render for first time. 
    // useEffect runs after the render cycle. it will run once in the unauthenticated
    // version and then useEffect will run.
    useEffect(() => {
        // we know this is JSON format
        const storedData = JSON.parse(localStorage.getItem(`userData`));
        // here we're checking to see if the current time stamp is in the future and if so, it logs the person in
        if(storedData && storedData.token && new Date(storedData.expiration) > new Date()) {
        // need to run the login function after it has been defined and pass
        // userId, token, and current time to expiration
        login(storedData.userId, storedData.token, storedData.email, new Date(storedData.expiration))
        }
        // because login uses useCallback, we don't need to worry about an infinite loop
    }, [login]);

    return { email, token, login, logout, userId };
}