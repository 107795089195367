import React from 'react';
import "../design/Footer.css"



function Footer() {
    return (
      <React.Fragment>
        <footer className={"footer"}>
        <b>© 2021 <u>Ruslan Polyak</u> All rights reserved.</b>
        </footer>
      </React.Fragment>
    )
}

export default Footer;