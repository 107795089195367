import React, { useState, useContext, useRef, useEffect, useCallback } from 'react'
import { Button, Modal, Form, Spinner } from 'react-bootstrap';
import "../design/Login.css";
import { AuthContext } from '../context/auth-context';


const Login = (props) => {

    const loginRef = useRef(null);

    const [ logStatus, setLogStatus ] = useState(false);

    const httpAbortCtrl = new AbortController();

    // this is the global login context we will populate
    // with loginAPI fetch response information
    const loginContext = useContext(AuthContext);

    // hide login modal
    // using callback here forces to render hideModal 
    // ONLY when a prop is passed
    const hideModal = useCallback(()=>{
        // clear the console in case of bad login
        console.clear()
        props.hideModal();
    },[props]);

    // error hook for notice on login modal
    const [ loginError, setLoginError ] = useState("");

    // email & password object hook to store input
    const [ login, setLogin ] = useState({
        email: "",
        password: ""
    });



    // event handler for email and password hook object
    const handleFormChange = (event) => {
        const { name, value } = event.target;
        setLogin((prev) => {
                return {
                ...prev,
                [name]: value
                }
            })
    };


    const loginAPI = async () => {
        try {
            const url = process.env.REACT_APP_BACKEND_URL;
            setLogStatus(true);
            const loginFetch = await fetch(`${url}/user/login`, {
                method: 'POST',
                headers: {
                'Content-Type' : 'application/json'
                },
                body: JSON.stringify({
                    email: login.email,
                    password: login.password
                }),
                signal: httpAbortCtrl.signal
            })
            if(loginFetch.ok) {
                const loginFetchResponse = await loginFetch.json();
                // update global context
                loginContext.login(loginFetchResponse.userId, loginFetchResponse.token, loginFetchResponse.email);
                loginRef.current = true;
                setLogStatus(false);
            } else if (!loginFetch.ok) {
                httpAbortCtrl.abort();
                setLogStatus(false);
                throw new Error(`Login failed! Please try again.`)
            }
            return await loginFetch;
        } catch (err) {
            // display error message
            setLoginError(err);
            loginRef.current = false;
            // removed console clear for now
            console.clear();

        }
    }

    const loginAPIHandler = (e) => {
        loginAPI();
        e.preventDefault();
    }
    // useEffect will allow the Wip component to render
    // I am guessing this has to do with order of how components
    // get rendered
    useEffect(() => {
        if(loginRef.current) {
            hideModal();
            loginRef.current = null;
        }
    },[hideModal])

    const SubmitBtn = (props) => {
        return (<Button className="submit" variant="success" as="input" onClick = {loginAPIHandler} type="submit" value="Submit" />);
    }
    const SpinnerBtn = (props) => {
        return (<Button variant="success"><Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true"/></Button>);
    }


    return (
        <React.Fragment>
            <Modal
                show = {props.show}
                backdrop = "static"
                keyboard = {false}
            >
                <Modal.Header>
                    <Modal.Title id = "contained-modal-title-vcenter">Login!</Modal.Title>
                </Modal.Header>
                    <Form>  
                        <Form.Group controlId="formBasicEmail">
                            <Form.Label>Email:</Form.Label>
                            <Form.Control onChange = {handleFormChange} name = "email" placeholder="Enter email" />
                        </Form.Group>
                        <p/>
                        <Form.Group controlId="formBasicPassword">
                            <Form.Label>Password:</Form.Label>
                            <Form.Control onChange = {handleFormChange} name = "password" type = "password" placeholder="Enter password" />
                            <Form.Text  className="text-muted"></Form.Text>
                        </Form.Group>
                        <p/>
                    </Form>
                <Modal.Footer>
                    <Form.Text className= {!!loginError ? "login-text" : "hidden"}>{`${loginError}`}</Form.Text>
                    <Button className="close" variant = 'danger' onClick = {hideModal}>Close</Button>
                    {logStatus ? <SpinnerBtn /> : <SubmitBtn />}
                </Modal.Footer>
            </Modal>
        </React.Fragment>
    )
}


export default Login;