import React from 'react';
import "../design/Aboutme.css";
import face from "../images/face.png";

function Aboutme(props) {


    return (
        <React.Fragment>
            <div className={"container-0"}>
                <div className="face"><img alt="face" className="face-img" src={face}></img></div>
                <article>
                    <h3>Hi.</h3>
                    <p>
                    I'm an analyst who is also a self-taught full-stack and Python developer based in Seattle, WA. I have a passion for learning and challenging myself.
                    </p>
                </article>
            </div>
            <div className={"container-1"}>
                <article>
                    <h3>My Personal Website.</h3>
                    <p>
                    I designed and developed this website in order to help me learn more about JavaScript, the MERN Stack, Web Development, and a bunch
                    of other skills not limited to: git version control, local storage, hashing passwords, and tokens.
                    </p>
                    <p>
                        The Wip section of this website is stored entirely on cloud.mongodb.com. 
                        The front-end is deployed with GitHub Pages and the back-end is deployed with Heroku. 
                        The icons (tweety bird, chalkboard, trash bin) embedded in the Wip entries will add, edit, and delete entries, respectively. 
                        You do need to be logged in as Admin in order to do any of those action items.
                    </p>
                    <p>
                        My next big project will be to add the ability to allow visitors to become members and comment on my Wip ideas.
                    </p>
                </article>
            </div>
            <div className={"container-2"}>
                <article>
                    <h3>I can help.</h3>
                    <p>
                    If you like my website or my work, please let me know by getting in touch with me via my Contact Form. 
                    If you would like to collaborate on projects or ideas, please reach out to me; I enjoy building websites 
                    and/or Python applications. 
                    </p>
 
                </article>
            </div>
        </React.Fragment>
    );
}

export default Aboutme;