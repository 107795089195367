import React, { useState } from 'react';
import { Form, Button, Modal, Spinner } from 'react-bootstrap';
import emailjs from 'emailjs-com';
import { init } from 'emailjs-com';
import "../design/Contact.css";

function Contact(props) {

  const [ showModal, setShowModal ] = useState(false);

  // button state
  const [ status, setStatus ] = useState(false);

  // hooks used for success/error message
  const [ error, setError ] = useState(false);
  const [ show, setShow ] = useState(false)

  // email & password object hook to store input
  const [ wipEntry, setWipEntry ] = useState({
    name: "",
    email: "",
    message: ""
  });


  // clear the form after success
  const formClearHandler = () => {
    setWipEntry({
      name: "",
      email: "",
      message: ""
    })
  }

  const modalHandler = () => {
    setShowModal(false);
    props.hideModal(showModal);
  }

  const handleFormChange = (event) => { 
      const { id, value } = event.target;
      setWipEntry((prev) => {
              return {
              ...prev,
              [id]: value
              }
          })
  };

  init(`${process.env.REACT_APP_EMAIL_USER_ID}`);
  const emailHandler = () => {
    // check for empty form fields before submittal
    if (wipEntry.name === '' || wipEntry.email === '' || wipEntry.message === '') {
      alert('Please make sure ALL fields are populated before submitting the form!')
    } else {
      setStatus(true);
      emailjs.send(`${process.env.REACT_APP_EMAIL_SERVICE_ID}`, "template_14n18k3", wipEntry)
      .then((response)=>{
        // if email is sent, response ok
        setError(response.text);
        setShow(true);
        // clear form upon success
        formClearHandler();
        // hide the form after a successful send
        setTimeout(modalHandler, 1000);
        setStatus(false)
      }, (err)=>{
        setStatus(false)
        setError("");
        setShow(true)
      });
    }
  }

  const SendBtn = () => {
    return (<Button variant = "success" onClick = {emailHandler}>Send</Button>);
  }
  const SpinnerBtn = () => {
    return (<Button variant="success"><Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true"/></Button>);
  }

  return (
    <React.Fragment>
      <>
        <Modal
          show = {props.show}
          backdrop = "static"
          keyboard = {false}
        >
          <Modal.Header className = "modal-contact-header">
              <Modal.Title className = "modal-contact-title">Let's Connect!</Modal.Title>
          </Modal.Header>
            <Form onSubmit = {(e)=>e.preventDefault()}>
              <Form.Group id="name">
                <Form.Label>Name</Form.Label>
                <Form.Control type="name" value = {wipEntry.name} id="name" onChange = {handleFormChange}/>
              </Form.Group>
              <Form.Group id="email">
                <Form.Label>Email</Form.Label>
                <Form.Control type="email" value = {wipEntry.email} id="email" onChange = {handleFormChange} placeholder="name@example.com" />
              </Form.Group>
              <Form.Group id="message">
                <Form.Label>Message</Form.Label>
                <Form.Control as="textarea" value = {wipEntry.message} id="message" onChange = {handleFormChange} rows={3} />
              </Form.Group>
            </Form>
          <Modal.Footer>
            {show && (<Form.Text className = {error === "OK" ? "sent_message" : "error_message"}>{error === "OK" ? "Email sent!" : "Something went wrong! Please try again later."}</Form.Text>)}
            <Button variant = "danger" onClick = {modalHandler}>Close</Button>{' '}
            {status ? <SpinnerBtn /> : <SendBtn />}
          </Modal.Footer>
        </Modal>
      </>
    </React.Fragment>
  )
}

export default Contact;