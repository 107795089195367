import React, { useState } from 'react';
import "../src/design/App.css"
import NavbarNav from './components/Navbar';
import Contact from "./components/Contact";
import sun from "../src/images/sun.png";
import airplane from "../src/images/airplane.png";
import Footer from './components/Footer';
import Wip from "../src/components/Wip";
import About from "../src/components/About";
import Intro from "../src/components/Intro";
import Work from "../src/components/Work";
import Aboutme from './components/Aboutme';

// AuthContext holds the login credentials for global use
import { AuthContext } from "../src/context/auth-context";
// auth-hook manages the local storage via login, & logout
import { useAuth } from "./hooks/auth-hook";

function App() {

  // this function gets passed to child in navbar as a prop
  // this Wip section
  const [click, setClick] = useState(false);
  
  // also used to trigger a render and fetchAPI
  const workInProgress = () => {
    click ? setClick(false) : setClick(true);
  }

  const { email, token, login, logout, userId } = useAuth();

  // check: if user leaves website and then returns
  // potentially consider notifying user if he's been logged out
  // and ask them to log back in. Implement this feature later.

  return (
    <React.Fragment>
      <AuthContext.Provider value ={{ 
      email: email,
      isLoggedIn: !!token, 
      token: token,
      userId: userId, 
      login: login, 
      logout: logout }}>
      
        <NavbarNav click = {click} onClick = {workInProgress}  />
        <Work />
        <Intro airplane={airplane} sun={sun} />
        <About />
        <Contact />
        {click && (<Wip fetchWip = {click} fetchRecordsApi = {workInProgress} />)}
        {!click && (<Aboutme />)}
        <Footer />
      </AuthContext.Provider>
    </React.Fragment>
  );
}

export default App;
