import React from 'react';
import ReactDOM from 'react-dom';
import App from "./App";
import "./design/Index.css";


ReactDOM.render(
  // <React.StrictMode>
      <App />,
  // </React.StrictMode>,
  document.getElementById('root')
);

