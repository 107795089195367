import { createContext } from 'react';

// i believe these are global variables
export const AuthContext =  createContext ({
    isLoggedIn: false, 
    email: null,
    userId: null,
    token: null,
    login: () => {}, 
    logout: () => {} }
);