import React, { useState } from 'react';
import { Modal, Button } from "react-bootstrap";
import "../design/Work.css";

function Work(props) {

    const [ workModal, setWorkModal ] = useState(false);

    const handleModal = () => {
        setWorkModal(false);
        props.hideModal(workModal);
    }

    return (
    <>
        <Modal show={props.show} onHide={handleModal} scrollable={true}>
        <Modal.Header>
          <Modal.Title>Projects</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div className="modal-body">
                <span><u>Preface</u>: because my projects all rest on servers that are hobby tier, it's possible some may not load due to inactivity. 
                If this happens, please let me know via my Contact Form.</span>
                <ul className="roman">
                    <li>Python Projects:
                        <ul className="sub">
                            <li><a href = "https://prprealestate.pythonanywhere.com/" target="_blank" rel="noreferrer">https://prprealestate.pythonanywhere.com</a></li>
                            <li className = "indent">Designed and developed a real estate marketing website using Django 3.1.1 and Bootstrap v4.5.2. 
                            The primary intent of this project was to learn Python, HTML, CSS, and web layout design.</li>
                            <li><a href="https://chefgravy.pythonanywhere.com" target="_blank" rel="noreferrer">https://chefgravy.pythonanywhere.com</a></li>
                            <li className = "indent">Designed and architected a social media web platform using Django 3.1.1 and Bootstrap v4.5.2.
                            The intent of this project was to learn more about Django and Bootstrap via Documentation and online tutorials.</li>
                            <li><a href="https://github.com/ChefGravy/PythonProj" target="_blank" rel="noreferrer">https://github.com/ChefGravy/PythonProj</a></li>
                            <li className = "indent">Developed a Windows based Application utilizing Python/Tkinter and SQLite. 
                            The purpose was to create a database/repository to help facilitate visualization and analysis of business cases and financial metrics.</li>
                        </ul>
                    </li>
                    <li>JavaScript projects:
                        <ul className="sub">
                        <li><a href="https://www.ruslanpolyak.com" target="_blank" rel="noreferrer">https://www.ruslanpolyak.com</a></li>
                            <li className = "indent">Designed and developed my personal website utilizing the MERN Stack.</li>
                            <li className = "indent">Front-end: GitHub Pages. I absolutely love this service!</li>
                            <li className = "indent">Back-end: Heroku.</li>
                            <li className = "indent">Database: cloud.mongodb.com.</li>
                            <li><a href="https://simon-game-project.netlify.app/" target="_blank" rel="noreferrer">https://simon-game-project.netlify.app/</a></li>
                            <li className = "indent">Developed a game called Simon based on an electronic game that was introduced in 1978.
                            This project primarily helped me understand more about JavaScript and publishing websites.</li>
                            <li><a href = "https://todolist-proj.herokuapp.com/" target="_blank" rel="noreferrer">https://todolist-proj.herokuapp.com/</a></li>
                            <li className = "indent">Developed a to-do list using EJS. The purpose of this project was to learn how to use JavaScript Templates, Mongoose, and Node.</li>
                            <li className = "indent">Database: https://cloud.mongodb.com/</li>
                            <li><a href = "https://keeper-app-proj.herokuapp.com/" target="_blank" rel="noreferrer">https://keeper-app-proj.herokuapp.com/</a></li>
                            <li className = "indent">Developed a Keeper app using React. This is based on the Google Keep app.
                            This was my first React based project that I built that helped me contrast the working details with Django.</li>
                            <li><a href = "https://keen-meninsky-d672f2.netlify.app" target="_blank" rel="noreferrer">https://keen-meninsky-d672f2.netlify.app</a></li>
                            <li className = "indent">Developed a game using JavaScript based on the 1945 Pig game by John Scarne. </li> 
                        </ul>
                    </li>
                </ul>
            </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
    )
}

export default Work;