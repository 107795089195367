import { useCallback, useState } from 'react';

export const useFetchRequest = () => {
    
    const [ error, setError ] = useState("")

    const clearError = () => {
        setError("");
    }

    const fetchRecord = useCallback(async (id) => {
        try{
            const url = process.env.REACT_APP_BACKEND_URL;
            const response = await fetch(`${url}/wip/record/${id}`, {
                method: 'GET',
                mode: 'cors',
                cache: 'no-cache',
                headers: {
                    'Content-Type' : 'application/json'
                },
            })
            if(!response.ok) {
                throw new Error('Something went wrong! Please try making updates a bit later.')
            }
            // read response
            const fetchRecordResponse = await response.json();
            // return response
            return fetchRecordResponse;

        } catch (err) {
            setError(err)
        }
    },[]);

    return {fetchRecord, error, clearError};
};

