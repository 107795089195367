import React, { useContext, useState } from 'react'
import { Form, Button, Spinner } from 'react-bootstrap';
import { AuthContext } from "../context/auth-context";

import ErrorModal from './ErrorModal';
import "../design/WipEntryForm.css";

function NewWipEntry(props) {

    // status spinner
    const [isLoading, setLoading] = useState(false);

    // error hooks for modal
    const [ error, setError ] = useState(false);
    const [ errorMsg, setErrorMessage ] = useState("");

    // email & password object hook to store input
    const [ wipEntry, setWipEntry ] = useState({
        title: "Title",
        description: "Entry"
    });

    // this is the global login context we will populate
    // with loginAPI fetch response information
    const loginContext = useContext(AuthContext);

    // event handler for email and password hook object
    const handleFormChange = (event) => { 
        const { name, value } = event.target;
        setWipEntry((prev) => {
                return {
                ...prev,
                [name]: value
                }
            })
    };

    // login API
    const newWipEntryAPI = async () => {
        try {
            setLoading(true);
            const url = process.env.REACT_APP_BACKEND_URL;
            const wipEntryAPI = await fetch(`${url}/wip/entry`, {
                method: 'POST',
                mode: 'cors',
                cache: 'no-cache',
                headers: {
                    Authorization:  `Bearer ` + loginContext.token,
                    'Content-Type' : 'application/json'
                },
                body: JSON.stringify({
                    title: wipEntry.title,
                    description: wipEntry.description
                })
            })
            // we don't need to read any values from here since the entry wil be posted on the site
            //const wipEntryAPIResult = await wipEntryAPI.json();
            // status spinner update if data is available from mongodb but before hook is updated
            if(wipEntryAPI.ok && wipEntryAPI.status === 201) {
                // clear the spinner
                setLoading(false);
                // refresh the wip posts to reflect latest post
                props.refresh();
                // reset upon successful update
                setWipEntry({
                    title: "Title",
                    description: "Entry"
                })
            } else {
                setError(!wipEntryAPI.ok);
                // clear the spinner
                setLoading(false)
                throw new Error(`Entry failed! Please try again.`)
            }
        } catch (err) {
            // remove loader
            setLoading(false)
            // display error message
            setErrorMessage(err.message)
        }
    }

    // reset the error Modal
    const errorModalHandler = (state) => {
    setError(state);
  }

    const UploadBtn = (props) => {
        return (<Button variant="success" type="submit" onClick = {newWipEntryAPI}>Upload</Button>);
    }
    const SpinnerBtn = (props) => {
        return (<Button variant="success"><Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true"/></Button>);
    }

    return (
        <React.Fragment>
            <ErrorModal show = {error} errorHandler = {errorModalHandler} errorMsg = {errorMsg} />    

            <Form id = "wip-entry-form" onSubmit = {(e)=>e.preventDefault()}>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Label>Title</Form.Label>
                <Form.Control type="title" name = "title" value = {wipEntry.title} onChange = {handleFormChange} placeholder="Title" />
            </Form.Group>
            <Form.Group className="mb-3" controlId="wip-entry">
                <Form.Label>Wip Entry</Form.Label>
                <Form.Control as="textarea" name = "description" value = {wipEntry.description} onChange = {handleFormChange} placeholder = "Note" rows={3} />
            </Form.Group>
            {/* <Button variant="success" type="submit" onClick = {newWipEntryAPI}>Upload</Button> */}
            {isLoading ? <SpinnerBtn /> : <UploadBtn />}
            <Button id = "close-button" variant="danger" onClick = {props.close} type="submit">Close</Button>
            </Form>
        </React.Fragment>
    )

}

export default NewWipEntry;