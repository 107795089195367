import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import ModalHeader from 'react-bootstrap/ModalHeader'
import "../design/About.css";


function About(props) {
    
    const [show, setShow] = useState(false);

    const handleModal = () => {
        setShow(false)
        props.hideModal(show)
    }

    return (
    <React.Fragment>
        <Modal show={props.show} onHide={handleModal}>
        <ModalHeader>
          <Modal.Title className = 'about-header-title'>Me!</Modal.Title>
        </ModalHeader>
        <Modal.Body>
        <div className="about">
            Hi. I am Ruslan, an analyst, a self taught web developer, and a tinkerer.
            I have a constant passion for learning, asking questions and gaining new skills. 
            <p/> I got into web development as a result of a Python based project I had at work. 
            Once my curiosity took over, I couldn't stop and all I wanted to do was research so 
            I could understand more about Python and how to deploy my code. After
            a few online tutorials and and some classes, I had the necessary skills to get started.
            <p/>If you navigate over to my "Work", most of my projects are posted there. If you 
            have any questions about my projects, or you're interested in collaborating, please don't hesitate to get in touch with me
            via my Contact form. 
            <p/>
            <p/>Ruslan!
                </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>




    </React.Fragment>
  )
}

export default About;