import React, { useContext, useState, useRef, useCallback, useEffect } from 'react'
import delete_btn from "../images/delete-btn.png";
import "../design/WipEntry.css";
import edit_btn from "../images/comment.png";
import Login from "../components/Login";
import { AuthContext } from '../context/auth-context';
import EditModal from "./EditModal";
import { useFetchRequest } from "../hooks/fetch-id-hook";

// attempt to handle
function useIsMountedRef(){
    const isMountedRef = useRef(null);
    useEffect(() => {
      isMountedRef.current = true;
      return () => isMountedRef.current = false;
    });
    return isMountedRef;
}

function WipEntry(props) {

    // custom hook for mountedRef
    const isMountedRef = useIsMountedRef();

    const individualRecordId = useRef(null);

    // display login modal toggle
    const [ loginToggle, setLoginToggle ] = useState(false);
    // display edit modal toggle
    const [ editToggle, setEditToggle ] = useState(false);
    
    // login AuthContext
    const loginContext = useContext(AuthContext);

    // date format for wipEntry
    const options = {month: "long", day: "numeric", year: "numeric"};
    const date = new Date(props.createdAt);
    const westDate = new Intl.DateTimeFormat("en-US", options).format(date);

    // fetch record hook to prepoluate edit form
    const { fetchRecord, error } = useFetchRequest();
    // capture the single id record
    const [ singleId, setSingleId ] = useState();

    // closes the login
    const loginHandler = (val) => {
        setLoginToggle(!!val)
    }

    const editRecord = useCallback(async(wipEdit) => {
        try{
            const url = process.env.REACT_APP_BACKEND_URL;
            const response = await fetch(`${url}/wip/update/${individualRecordId.current}`, {
                method: 'PATCH',
                headers: {
                    Authorization: `Bearer ` + loginContext.token,
                    'Content-Type' : 'application/json'
                },
                body: JSON.stringify({
                    title: wipEdit.title,
                    description: wipEdit.description
                })
            })
            if(response.ok) {
                props.refresh();
                
            }
        } catch (err) {
        }
    },[loginContext.token, props]);

    // checks for token and triggers login or deletes record
    const deleteItem = (val) => {
        if(!loginContext.token) {
            loginHandler(val);
        } else {
            //confirm delete
            const message = 'Are you sure you want to delete this?!'
            const result = window.confirm(message)
            if (result === true) {
                // accepts the prop.id and sends back to delete
                // if authenticated, otherwise, prompt to authenticate
                props.deleteAction(props.id);
            }

            
        }
    };

    // edit items action with authentication
    // id is pulled from two places
        const editItem = useCallback((wipEdit) => {
            if(!!loginContext.token) {
                editRecord(wipEdit);
            }
        },[editRecord, loginContext.token]);


    // show or hide edit modal based on login status
    const editModalHandler = (val) => {
        // check it token exists, throw login modal
        if(!loginContext.token) {
            loginHandler(true);
            // if edit modal is on, close it
        } else if(editToggle) {
                setEditToggle(false);
        } else {
            // set ref to prop.id clicked on
            // id is pulled from two places
            if(typeof val.target !== 'undefined' || null) {
                individualRecordId.current = val.target.id;
                if(isMountedRef.current) {
                    fetchSingleId();
                }
            }
            setEditToggle(true);
        }
    }

    const fetchSingleId = useCallback(async () => {
        try{
            const fetchWipRecord = await fetchRecord(individualRecordId.current)
            // use hook to populate and pass via component prop "prepop"
            setSingleId(fetchWipRecord.entry)
        } catch(err){
            // already handled
        }
    },[fetchRecord]);

    return (
        <React.Fragment>
            <EditModal show = {!!editToggle} id = {props.id} hideModal = {editModalHandler} error = {error} prepop = {singleId} editItem = {editItem} />
            <Login show = {!!loginToggle} hideModal = {loginHandler} />
            <div className = {"updates"}>
                {westDate && (<span className = "date">{westDate}</span>)} 
                <span className = "title">{props.title}</span>
                <br/><br/>
                <ul>
                {props.description}
                </ul>
                <div className = "comment_trash">
                    <div className = {"edit_btn"}> <img alt="edit" id = {props.id} onClick = {editModalHandler} src = {edit_btn} /></div>
                    <div className = {"delete_btn"}> <img alt="delete" onClick = {deleteItem} src = {delete_btn} /></div>
                </div>
            </div>
        </React.Fragment>
    )
};

export default WipEntry;