import React, { useState } from 'react';
import { Navbar, Button } from 'react-bootstrap';
import "../design/Navbar.css";
import instagram_logo from "../images/instagram_logo.png";
import github_logo from "../images/Octocat.png";
import linkedin_logo from "../images/linkedin.png";
import Contact from "./Contact";
import About from "./About";
import Work from "./Work";
// import { AuthContext } from "../context/auth-context";

function NavbarNav(props) {

    // const loginContext = useContext(AuthContext);

    const [ workModal, setWorkModal ] = useState(false)
    const [ contactModal, setContactModal ] = useState(false);
    const [ aboutModal, setAboutModal ] = useState(false);

    const workHandler = () => {
        if(workModal) {
            setWorkModal(false);
        }
        if(!workModal) {
            setWorkModal(true);
        }
    }

    const contactHandler = (val) => {
        if(contactModal) {
            setContactModal(false);
        }
        if(!contactModal) {
            setContactModal(true);
        }
    }

    const aboutHandler = () => {
        if(aboutModal) {
            setAboutModal(false)
        }
        if(!aboutModal) {
            setAboutModal(true);
        }
    }

    return (
        <React.Fragment>
            <Navbar bg="#11999e" id="main-nav" collapseOnSelect expand="lg" fixed="top">
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="navbarScroll">
                        <Button variant="outline-info" className="navbar-nav-btn-work" onClick={workHandler}>Work</Button>
                        <Button variant="outline-info" className="navbar-nav-btn" onClick={aboutHandler}>About</Button>
                        <Button variant="outline-info" className="navbar-nav-btn" onClick={props.onClick}>{props.click ? "Close Wip" : "Wip"}</Button>
                        <Button variant="outline-info" className="navbar-nav-btn" onClick={contactHandler}>Contact</Button>
                    </Navbar.Collapse>
                    <Navbar.Collapse className="icons_nav">
                        <a className="icons" href="https://www.instagram.com/pepper.rodney/"><img src={instagram_logo} alt="" width="24" height="24" /></a>
                        <a className="icons" href="https://github.com/ChefGravy"><img src={github_logo} alt="" width="24" height="24" /></a>
                        <a className="icons" href="https://www.linkedin.com/in/ruslanpolyak/"><img src={linkedin_logo} alt="" width="24" height="24" /></a>
                    </Navbar.Collapse>
            </Navbar>
            <Contact show={contactModal} hideModal={contactHandler} />
            <About show={aboutModal} hideModal={aboutHandler} />
            <Work show={workModal} hideModal={workHandler} />
        </React.Fragment>
    );
}

export default NavbarNav;